import ColorHandler from "./ColorHandler";

export default class Paths {

    constructor() {

        this.piecesPaths = {};
        this.trimModelsPaths = {};
        this.trimPalettesPaths = {};

        this.trimData = this._getTrimData();

        this.addBodyPieces();
        this.addTrimModels();
        this.addTrimPalettes();
        this.addLeatherColors();
    }

    _getTrimData() {

        //Bolt, flow 

        return {
            "bolt": {
                "desc": "Bolt templates can be discovered in standard vaults located within trial chambers.",
                "dupe_material": "Copper Block",
                "chance_to_find": 0.063,
                "box_type": "chests",
                "location": "standard vaults in trial chambers"
            },
            "coast": {
                "desc": "Coast templates can be found in shipwrecks",
                "dupe_material": "Cobblestone",
                "chance_to_find": 0.167,
                "box_type": "chests",
                "location": "shipwrecks"
            },
            "dune": {
                "desc": "The Dune template can be found in pyramids",
                "dupe_material": "Sandstone",
                "chance_to_find": 0.143,
                "box_type": "chests",
                "location": "pyramids"
            },
            "eye": {
                "desc": "1 Eye template is guaranteed in every Stronghold library chest and can also be found in altar chests",
                "dupe_material": "End Stone",
                "chance_to_find": 0.1,
                "box_type": "altar chests",
                "location": "Stronghold library"
            },
            "flow": {
                "desc": "Flow armor trims are found in ominous vaults within trial chambers.",
                "dupe_material": "Breeze Rod",
                "chance_to_find": 0.225,
                "box_type": "chests",
                "location": "ominous vaults in trial chambers"
            },
            "rib": {
                "desc": "Rib templates can be found in chests in nether fortresses.",
                "dupe_material": "Netherrack",
                "chance_to_find": 0.067,
                "box_type": "chests",
                "location": "nether fortresses"
            },
            "sentry": {
                "desc": "Sentry templates are hidden in pillager outpost chests",
                "dupe_material": "Cobblestone",
                "chance_to_find": 0.25,
                "box_type": "chests",
                "location": "pillager outposts"
            },
            "snout": {
                "desc": "The Snout template can be found in bastion remnants",
                "dupe_material": "Blackstone",
                "chance_to_find": 0.083,
                "box_type": "chests",
                "location": "bastion remnants"
            },
            "spire": {
                "desc": "Travel to end cities to find the Spire template",
                "dupe_material": "Purpur Block",
                "chance_to_find": 0.067,
                "box_type": "chests",
                "location": "end cities"
            },
            "tide": {
                "desc": "The Tide template is dropped by elder guardians found in ocean monuments",
                "dupe_material": "Prismarine",
                "chance_to_find": 0.2,
                "box_type": "dropped by elder guardians",
                "location": "ocean monuments"
            },
            "vex": {
                "desc": "The Vex template can be found in chests in Woodland Mansions.",
                "dupe_material": "Cobblestone",
                "chance_to_find": 0.5,
                "box_type": "chests",
                "location": "Woodland Mansions"
            },
            "ward": {
                "desc": "The Ward template can be found in ancient cities",
                "dupe_material": "Cobbled Deepslate",
                "chance_to_find": 0.05,
                "box_type": "chests",
                "location": "ancient cities"
            },
            "wild": {
                "desc": "Wild templates can be found in jungle temples",
                "dupe_material": "Mossy Cobblestone",
                "chance_to_find": 0.333,
                "box_type": "chests",
                "location": "jungle temples"
            },
            "wayfinder": {
                "desc": "This armor trim can be found in the trial ruins in suspicious gravel",
                "dupe_material": "Terracotta",
                "chance_to_find": 0.083,
                "box_type": "suspicious gravel",
                "location": "trial ruins"
            },
            "raiser": {
                "desc": "This armor trim can be found in the trial ruins in suspicious gravel",
                "dupe_material": "Terracotta",
                "chance_to_find": 0.083,
                "box_type": "suspicious gravel",
                "location": "trial ruins"
            },
            "shaper": {
                "desc": "This armor trim can be found in the trial ruins in suspicious gravel",
                "dupe_material": "Terracotta",
                "chance_to_find": 0.083,
                "box_type": "suspicious gravel",
                "location": "trial ruins"
            },
            "host": {
                "desc": "This armor trim can be found in the trial ruins in suspicious gravel",
                "dupe_material": "Terracotta",
                "chance_to_find": 0.083,
                "box_type": "suspicious gravel",
                "location": "trial ruins"
            },
            "silence": {
                "desc": "Silence trims are rare and can be found in chests in ancient cities.",
                "dupe_material": "Cobbled Deepslate",
                "chance_to_find": 0.012,
                "box_type": "chests",
                "location": "ancient cities"
            }
        };
    }

    /**
     * @type int
     * @returns int[]
     */
    getDiceLayout(randomIndex) {

        let dieLayout = [

            [4],
            [0, 8],
            [0, 4, 8],
            [0, 2, 6, 8],
            [0, 2, 4, 6, 8],
            [0, 2, 3, 5, 6, 8],
        ]

        return dieLayout[randomIndex];
    }

    getBaseSkins() {

        return {
            "alex": "skins/alex.png",
            "ari": "skins/ari.png",
            "efe": "skins/efe.png",
            "kai": "skins/kai.png",
            "makena": "skins/makena.png",
            "noor": "skins/noor.png",
            "steve": "skins/steve.png",
            "sunny": "skins/sunny.png",
            "zuri": "skins/zuri.png"
        }
    }

    getMaterialPath(material, piece) {

        const icon_obj_path = "items/armor/";
        const texture_obj_path = "textures/armors/";
        return {

            name: `${material}_${piece}`,
            icon: icon_obj_path + (material == "gold" ? "golden" : material) + `_${piece}.png`,
            texture1: texture_obj_path + material + "_layer_1.png",
            texture2: texture_obj_path + material + "_layer_2.png",
            generateMethod: "texture_generate",
            model: piece
        }
    }

    findSubStringInArray(subString, array) {

        // console.log(array);
        const val = array.find(element => element.substring(0, subString.length) == subString);
        return val || array[0];
    }

    getTrimPath(model) {

        const trim_icon_path = "items/trims/";
        const smithing_end_path = "_armor_trim_smithing_template"
        const trim_texture_path = "textures/trims/";

        return {

            name: model,
            icon: trim_icon_path + model + smithing_end_path + ".png",
            texture1: trim_texture_path + model + ".png",
            texture2: trim_texture_path + model + "_leggings.png"
        };
    }

    getPalettePath(palette) {

        const palette_textures_path = "textures/palettes/";
        const palette_icons_path = "items/coloring/";

        return {

            name: palette,
            icon: palette_icons_path + palette + ".png",
            texture1: palette_textures_path + palette + ".png",
            texture2: this.trimPalettesDarker.includes(palette) ? palette_textures_path + palette + "_darker.png" : ""
        };
    }

    getDyePath(dye) {

        const dye_icons_path = "items/dye/";

        return {

            name: dye,
            icon: dye_icons_path + dye + "_dye.png",
        };
    }

    /** Returns all dye colors as decimals representation.
     * 
     * @returns {Object<string, int>} 
     */
    getDyeColors() {

        return {
            "black": 1908001,
            "blue": 3949738,
            "light_blue": 3847130,
            "brown": 8606770,
            "cyan": 1481884,
            "green": 6192150,
            "gray": 4673362,
            "light_gray": 10329495,
            "lime": 8439583,
            "magenta": 13061821,
            "orange": 16351261,
            "pink": 15961002,
            "purple": 8991416,
            "red": 11546150,
            "white": 16383998,
            "yellow": 16701501,
        }
    }

    /**
     * 
     * @param {*} color 
     * @returns {Array<int>} rgb array
     */
    getDyeColor(color) {

        const colors = this.getDyeColors();

        if (!Object.keys(colors).includes(color)) {

            console.log("color: ", color, "does not exist");
            return color;
        }

        return new ColorHandler().decimalToRGBArray(colors[color]);
    }

    addBodyPieces() {

        this.pieceTypes = [
            "helmet",
            "chestplate",
            "leggings",
            "boots",
        ]

        this.pieceMaterials = [

            "iron",
            "chainmail",
            "leather",
            "golden",
            "diamond",
            "netherite"
        ]

        this.piecesPaths = {};

        const icon_obj_path = "items/armor/";
        const texture_obj_path = "textures/armors/";

        for (const piece of this.pieceTypes) {

            const pieceType = {

            }

            for (const material of this.pieceMaterials) {

                const piecePaths = {

                    name: `${material}_${piece}`,
                    icon: icon_obj_path + material + `_${piece}.png`,
                    texture1: texture_obj_path + material + "_layer_1.png",
                    texture2: texture_obj_path + material + "_layer_2.png",
                    generateMethod: "texture_generate",
                    model: piece
                };

                pieceType[material] = piecePaths;
            }

            this.piecesPaths[piece] = pieceType;

        }

    }

    addTrimModels() {

        this.trimModels = Object.keys(this._getTrimData());
        this.trimModels.push("empty");

        this.trimModelsPaths = {};

        const trim_icon_path = "items/trims/";
        const smithing_end_path = "_armor_trim_smithing_template"
        const trim_texture_path = "textures/trims/";

        for (const model of this.trimModels) {

            const modelPaths = {

                name: model,
                icon: trim_icon_path + model + smithing_end_path + ".png",
                texture1: trim_texture_path + model + ".png",
                texture2: trim_texture_path + model + "_leggings.png"
            };

            this.trimModelsPaths[model] = modelPaths;
        }
    }

    addTrimPalettes() {

        this.trimPalettes = [
            "amethyst",
            "copper",
            "diamond",
            "emerald",
            "gold",
            "iron",
            "lapis",
            "netherite",
            "quartz",
            "redstone",
            "resin"
        ];

        this.trimPalettesDarker = [

            "iron",
            "gold",
            "diamond",
            "netherite"
        ];

        this.trimPalettesPaths = {};

        const palette_textures_path = "textures/palettes/";
        const palette_icons_path = "items/coloring/";

        for (const palette of this.trimPalettes) {

            const palettePaths = {

                name: palette,
                icon: palette_icons_path + palette + ".png",
                texture1: palette_textures_path + palette + ".png",
                texture2: this.trimPalettesDarker.includes(palette) ? palette_textures_path + palette + "_darker.png" : ""
            };

            this.trimPalettesPaths[palette] = palettePaths;
        }
    }

    addLeatherColors() {

        this.leatherColors = [

            "black",
            "blue",
            "light_blue",
            "brown",
            "cyan",
            "green",
            "gray",
            "light_gray",
            "lime",
            "magenta",
            "orange",
            "pink",
            "purple",
            "red",
            "white",
            "yellow",
        ]

        this.colors = {};
        const icon_obj_path = "items/dye/";

        for (const color of this.leatherColors) {

            this.colors[color] = {

                name: color,
                icon: `${icon_obj_path}/${color}_dye.png`
            }
        }
    }

    fixMaterialNameGolden(materialName) {

        return materialName == "golden" ? "gold" : materialName;
    }

    getTrimInfo(trimName) {

        // console.log(this.trimData);
        return this.trimData[trimName];
    }
}